
// @ts-nocheck
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import { format } from "date-fns";

export default defineComponent({
  setup(props) {
    const form = ref({});

    const assignments = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const customers = ref([]);
    const user_id = ref(0);
    const customer = computed(() => {
      return customers.value?.find((c: any) => c.id == user_id.value);
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);
    const day = ref(0);
    const date = ref(new Date());

    const today = computed(() => new Date());
    const week = ref({
      start: new Date(),
      end: new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000)
    });
    const masks = ref({
      input: "YYYY-MM-DD"
    });

    function getDates(start: any, stop: any) {
      const dates = [];
      let currentDate = moment(start);
      const stopDate = moment(stop);
      while (currentDate <= stopDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function createForm(a: any) {
      const dates = getDates(week.value.start, week.value.end);

      dates.forEach((d: any) => {
        Object.defineProperty(form.value, d, {
          value: {},
          writable: true,
          enumerable: true,
          configurable: true
        });

        const ds = form.value[d];

        a.map((b: any) => {
          Object.defineProperty(ds, b.id, {
            value: 0,
            writable: true,
            enumerable: true,
            configurable: true
          });
        });
      });
    }

    function searchAssignments() {
      //processing.value = true;
      http.get(`/api/assignments?s=${search.value}`).then(res => {
        assignments.value = res.data.data;
        //processing.value = false;
      });
    }

    function fetchCustomers() {
      processing.value = true;
      http.get(`/api/users?role=customer&fetch=all`).then(res => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    function fetchAssignments(user_id: any) {
      processing.value = true;
      http.get(`/api/assignments?user_id=${user_id}`).then(res => {
        assignments.value = res.data.data;
        createForm(res.data.data);
        processing.value = false;
      });
    }

    function saveOrders() {
      http
        .post("/api/orders", {
          user_id: user_id.value,
          orders: form.value,
          start: week.value.start,
          end: week.value.end
        })
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          fetchAssignments(user_id.value);
        });
    }

    function updateAssignment(assignment: any) {
      record._method = "PUT";
      http
        .post(`/api/assignments/${assignment.id}`, assignment)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchAssignments();
        });
    }

    function deleteAssignment(id: any) {
      http.post(`/api/assignments/${id}`, { _method: "DELETE" }).then(res => {
        fetchAssignments();
      });
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    onMounted(() => {
      form.value = fetchCustomers();
    });

    watch(
      () => week.value,
      (v: any) => {
        if (assignments.value) {
          form.value = {};
          createForm(assignments.value);
        }
      }
    );

    function updateForm() {
      createForm(assignments.value);
    }

    return {
      fetchAssignments,
      processing,
      search,
      searchAssignments,
      assignments,
      saveOrders,
      created,
      assignment,
      updateAssignment,
      updated,
      deleteAssignment,
      fD,
      today,
      week,
      masks,
      getDates,
      setDay,
      day,
      date,
      form,
      customers,
      customer,
      user_id,
      updateForm
    };
  }
});
