<template>
  <div class="ng-content-wrap">
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>
              Week of
              <b>
                {{ fD(week.start, "E, do MMM") }}
              </b>
              to
              <b>
                {{ fD(week.end, "E, do MMM") }}
              </b>
              . Values in Kg.
            </p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text bg-primary text-white font-weight-bold"
                  >
                    Customer
                  </span>
                </div>
                <Multiselect
                  :options="customers"
                  v-model="user_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Start typing..."
                  @change="fetchAssignments"
                />
              </div>
            </li>
            <li>
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-plus-c"></em>
                <span>
                  <span class="d-none d-sm-inline-block">
                    Select
                  </span>
                  Week
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">
                        Select Date Range
                      </h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker
                          value="range"
                          v-model="week"
                          is-range
                          @drag="updateForm"
                        />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ fD(week.start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ fD(week.end) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span>
                            Use Selected Dates
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card card-bordered" v-else>
        {{ form }}
        <table class="table table-tranx">
          <thead>
            <tr class="tb-tnx-head">
              <th class="tb-tnx-info">
                <input
                  type="text"
                  class="form-control form-control-sm d-print-none"
                  placeholder="Search Assignment"
                  v-model="search"
                  @keyup="searchAssignments"
                />
              </th>
              <th
                v-for="(d, i) in getDates(week.start, week.end)"
                :key="i"
                class="text-center align-middle"
              >
                {{ fD(new Date(d), "E, do") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(p, i) in assignments" :key="i" class="tb-tnx-item">
              <td class="tb-tnx-info align-middle">
                <div class="tb-tnx-desc">
                  <span class="title">
                    {{ p.assignments?.name }}
                  </span>
                </div>
              </td>
              <td
                v-for="(d, i) in getDates(week.start, week.end)"
                :key="i"
                class="tab-pane"
                :id="`day${i}`"
                :class="{ active: i == day }"
              >
                <template v-if="form[d]">
                  <input
                    class="form-control"
                    type="number"
                    v-model="form[d][p.id]"
                  />
                </template>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="9">
                <button
                  class="btn btn-primary float-right d-print-none"
                  @click.prevent="saveOrders"
                >
                  <em class="icon ni ni-save"></em>
                  <span>
                    Save Orders
                  </span>
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// @ts-nocheck
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import { format } from "date-fns";

export default defineComponent({
  setup(props) {
    const form = ref({});

    const assignments = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const customers = ref([]);
    const user_id = ref(0);
    const customer = computed(() => {
      return customers.value?.find((c: any) => c.id == user_id.value);
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);
    const day = ref(0);
    const date = ref(new Date());

    const today = computed(() => new Date());
    const week = ref({
      start: new Date(),
      end: new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000)
    });
    const masks = ref({
      input: "YYYY-MM-DD"
    });

    function getDates(start: any, stop: any) {
      const dates = [];
      let currentDate = moment(start);
      const stopDate = moment(stop);
      while (currentDate <= stopDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function createForm(a: any) {
      const dates = getDates(week.value.start, week.value.end);

      dates.forEach((d: any) => {
        Object.defineProperty(form.value, d, {
          value: {},
          writable: true,
          enumerable: true,
          configurable: true
        });

        const ds = form.value[d];

        a.map((b: any) => {
          Object.defineProperty(ds, b.id, {
            value: 0,
            writable: true,
            enumerable: true,
            configurable: true
          });
        });
      });
    }

    function searchAssignments() {
      //processing.value = true;
      http.get(`/api/assignments?s=${search.value}`).then(res => {
        assignments.value = res.data.data;
        //processing.value = false;
      });
    }

    function fetchCustomers() {
      processing.value = true;
      http.get(`/api/users?role=customer&fetch=all`).then(res => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    function fetchAssignments(user_id: any) {
      processing.value = true;
      http.get(`/api/assignments?user_id=${user_id}`).then(res => {
        assignments.value = res.data.data;
        createForm(res.data.data);
        processing.value = false;
      });
    }

    function saveOrders() {
      http
        .post("/api/orders", {
          user_id: user_id.value,
          orders: form.value,
          start: week.value.start,
          end: week.value.end
        })
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          fetchAssignments(user_id.value);
        });
    }

    function updateAssignment(assignment: any) {
      record._method = "PUT";
      http
        .post(`/api/assignments/${assignment.id}`, assignment)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchAssignments();
        });
    }

    function deleteAssignment(id: any) {
      http.post(`/api/assignments/${id}`, { _method: "DELETE" }).then(res => {
        fetchAssignments();
      });
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    onMounted(() => {
      form.value = fetchCustomers();
    });

    watch(
      () => week.value,
      (v: any) => {
        if (assignments.value) {
          form.value = {};
          createForm(assignments.value);
        }
      }
    );

    function updateForm() {
      createForm(assignments.value);
    }

    return {
      fetchAssignments,
      processing,
      search,
      searchAssignments,
      assignments,
      saveOrders,
      created,
      assignment,
      updateAssignment,
      updated,
      deleteAssignment,
      fD,
      today,
      week,
      masks,
      getDates,
      setDay,
      day,
      date,
      form,
      customers,
      customer,
      user_id,
      updateForm
    };
  }
});
</script>
